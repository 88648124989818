.dealer-user-mapping-user {
    .top-heading {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
      background: $white;
      padding: 18px 15px;
    }
  }
  .dealer-user-mapping-tab-outer {
    .dealer-user-mapping-tabs {
      width: calc(100% + 30px);
      margin-left: -15px;
      margin-right: -15px;
      background: $white;
      padding: 0px 30px 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .tab-line{
        padding-bottom: 0px;
      }
      .btn-line{
        position: relative;
        top: -20px;
      }
    }
  }
  .table-heading-filter {
    display: flex;
    align-items: self-end;
    justify-content: space-between;
    padding: 20px 0px;
    .filter-right-panel {
      display: flex;
      align-items: center;
      fieldset {
        width: 250px;
        margin-left: 10px;
        &.single-select {
          width: 200px;
        }
      }
    }
  }
  .v-top {
    vertical-align: top !important;
  }
  .dealer-listing-table-data {
    background: $white;
    border-radius: 6px;
    table {
      th,
      td {
        &:first-child {
          width: 23%;
        }
      }
      th {
        &:last-child {
          width: 7%;
        }
      }
      td {
        padding-top: 18px !important;
        padding-bottom: 18px !important;
        &:nth-child(4) {
          padding: 0px !important;
          vertical-align: top;
        }
        &:nth-child(5) {
          padding: 0px !important;
          vertical-align: top;
        }
        &:nth-child(6) {
          padding: 0px !important;
          vertical-align: top;
        }
        &:nth-child(7) {
          padding: 0px !important;
          vertical-align: top;
          width: 5%;
        }
        .subtable {
          td {
            padding-left: 9px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &:last-child {
          td {
            padding-top: 13px !important;
            padding-bottom: 13px !important;
          }
        }
      }
      tbody {
        tr {
          &:hover {
            background: $white !important;
          }
        }
      }
    }
  }
  .action-btn {
    li {
      height: 32px;
      width: 32px;
      min-width: 32px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.06);
      margin-right: 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      cursor: pointer;
    }
  }
  .executive-listing{
    background: $white;
    border-radius: 6px;
    tr{
      th,td{
        &:first-child{
          padding-left: 10px !important;
          width: 16%;
        }
        &:nth-child(2){
          width: 14%;
        }
        &:nth-child(3){
          width: 8%;
        }
        &:nth-child(4){
          width: 8%;
        }
        &:nth-child(5){
          width: 7%;
        }
        &:nth-child(6){
          width: 12%;
        }
        &:nth-child(7){
          width: 10%;
        }
        &:nth-child(8){
          width: 10%;
        }
        &:last-child{
          padding-right: 10px !important;
          width: 7%;
        }
      }
    }
  }

  .table-heading-filter-no-visit {
    display: flex;
    align-items: self-end;
    justify-content: space-between;
    padding: 20px 0px;
    .filter-right-panel {
      display: flex;
      align-items: center;
      fieldset {
        width: 155px;
        margin-left: 10px;
        &.single-select {
          width: 200px;
        }
      }
    }
  }

  .dealer-user-maping-table{
    table{
      th{
        &:first-child{
          width: 18%;
        }
        &:nth-child(2){
          width: 17%;
        }
        &:nth-child(3){
          width: 20%;
        }
        &:nth-child(5){
          width: 20%;
        }
      }
      .subtable{
        td{
          height: 80px;
          span{
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .map-dealer-column{
        padding: 0px !important;
      }
    }
  }

  .show_ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dealer-listing-table-data {
    .map-dealer-column{
      padding: 0px !important;
    }
    table{
      td{
        &.last_visited_column{
          padding: 18px 9px !important;
        }
        &.status_text_column{
          padding: 18px 9px !important;
        }
      }
      
    }
  }

  .mapped_user_toggle{
    display: flex;
    align-items: center;
    padding-top: 3px;
  }

  