.error-404 {
    width: calc(100% - 593px);
    align-items: center;
    padding-left: 0;
    justify-content: center;
    padding-top: 0;
    
    .error {
        display: inline-block;
    }
    .ic-sad2{
        font-size: 100px;
        color: rgba(0,0,0,0.54);
        text-shadow: 0 3px 3px rgba(0,0,0,0.3);
    }


.error-text {
    font-size: 114px;
    text-shadow: 0 3px 3px rgba(0,0,0,0.3);
    color: rgba(0,0,0,0.54);
    .shake-icon {
        animation: shake 5s ease-in-out infinite;
        -webkit-animation: shake 5s ease-in-out infinite;
        margin: 0 8px;
        display: inline-block;
    }
}
  .gotohome{
    font-size: 30px;
    font-weight: bold;
    cursor: pointer;
    color:$accent-color;
  }
  }


@keyframes shake {
    0% {
        transform: rotate(-10deg)
    }

    50% {
        transform: rotate(20deg)
    }

    100% {
        transform: rotate(-10deg)
    }
}

@-webkit-keyframes shake {
    0% {
        -webkit-transform: rotate(-10deg)
    }

    50% {
        -webkit-transform: rotate(20deg)
    }

    100% {
        -webkit-transform: rotate(-10deg)
    }
}