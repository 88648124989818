.map-dealer-outer {
  background: $white;
  border-radius: 8px;
  padding: 30px;
  margin-top: 20px;

  .map-filter {
    padding-top: 20px;
    display: flex;

    fieldset {
      width: calc(20% - 10px);
      margin-right: 10px;
    }
  }
}

.dealer-details-outer {
  background: #EFF2F9;
  border-radius: 25px;
  padding: 30px;
  margin-top: 30px;

  h3 {
    font-weight: 600;
    font-size: 16px;
    line-height: 30px;
    color: $heading-color;
  }

  ul {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    li {
        width: 15%;
      label {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: rgba($heading-color, 0.7);
      }

      .detail-list {
        span {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          position: relative;
          color: rgba($heading-color, 0.87);
          padding-right: 15px;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          &::before {
            content: "\2022";
            font-weight: bold;
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            position: absolute;
            top: 0px;
            left: -12px;
            opacity: 0.4;
          }

          &:first-child {
            &::before {
              display: none;
            }
          }
        }
      }

      &:last-child {
        width: 23%;
      }
    }
  }
}

.sales-mapped-user-outer {
  margin-top: 30px;
  display: flex;
  align-items: start;
  justify-content: space-between;

  h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: $heading-color;
    padding-bottom: 10px;
  }

  .sales-users-left-panel {
    width: 49%;

    h3 {
      margin-left: 15px;
    }
  }

  .sales-mapped-bg {
    height: 530px;
    background: $white;
    border: 1px solid #E0E0E0;
    box-shadow: 0px 10px 35px rgba(0, 0, 0, 0.04);
    border-radius: 15px;
    padding: 30px;
  }

  .sales-user-list {
    ul {
      padding-top: 10px;
      height: 420px;
      overflow-y: auto;
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
      padding: 10px 30px 0;

      li {
        padding: 15px 30px;
        border-bottom: 1px solid $border-color;
        margin: 0px -30px;
        position: relative;

        label {
          width: 96%;
          font-weight: 500;
          font-size: 14px;
          line-height: 21px;
          color: $heading-color;
          display: flex;
          position: relative;

          &::before {
            content: "\2022";
            font-weight: bold;
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            opacity: 0.4;
            margin-right: 14px;
          }
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          padding-left: 22px;
        }

        .listclose-ic {
          // content: "\e925";
          // font-family: "dc" !important;
          position: absolute;
          right: 30px;
          background: #EFF2F9;
          width: 24px;
          text-align: center;
          border-radius: 24px;
          padding: 5px 0px;
          top: 15px;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }
}

.mapped-users-right-panel {
  width: 49%;

  .sales-user-list {
    position: relative;

    ul {
      height: 410px;
      // overflow-y: hidden;
      padding-top: 0px;

    }

    &.no-data-txt {
      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          &::after {
            display: none;
          }
        }
      }
    }
  }

  .btn-submit {
    border-top: 1px solid $border-color;
    padding-top: 20px;
    margin-left: -30px;
    padding-left: 30px;
    // position: absolute;
    bottom: 30px;
    width: calc(100% + 60px)
  }

  .tooltio-ic {
    padding-left: 0px !important;
  }
}

.tootip-outer {
  .react-tooltip-lite {
    background: $white;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;
    width: 280px !important;
    padding: 20px !important;
    // margin-left: 100px;
  }
  .react-tooltip-lite-arrow {
    border-bottom: 10px solid $white !important;
    // margin-left: 130px;
  }
  .controlled-example_close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }

  .user-detail {
    li {

      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
      color: $heading-color;
      &::before {
        content: "\2022";
        font-weight: bold;
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        opacity: 0.4;
        margin-right: 2px;
        position: relative;
        top: 0px;
      }
    }
  }
}

.map-dealer-main {
  .dealer-user-mapping-tabs {
    padding-bottom: 10px !important;
    padding-top: 15px !important;
  }
}